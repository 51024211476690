import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { HomeService } from './home/home.service';
import { PartyAgreementResponseModel } from './home/models/partyAgreementModel';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

//export class AppComponent implements OnDestroy {
export class AppComponent implements OnInit, OnDestroy {
  title = 'txt2ap';
  private isLinkExpired = false;app
  private appLoaded = false;
  private token: string;
  constructor(
    private router: Router,
    private translate: TranslateService,
    private homeService: HomeService,
    private route: ActivatedRoute
  ) {
    this.translate.setDefaultLang('en');
  }
  // SIMS-6795 Refresh WebApp UI navigates to an error page, hostlistener was not getting invoked, changes to ngOnInit
  // @HostListener('document:DOMContentLoaded', ['$event'])
  // goToHomeScreen($event) {
  ngOnInit(): void { 
    const url = location.href;
    
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get('token');
      if (paramValue != null) {
        this.navigationMethod(paramValue);
      }
    }
    else if (url.includes('linkexpired')) {
      this.appLoaded = true;
      this.router.navigateByUrl('/agreements/linkexpired');
    }
    else if (url.includes('createAccount') && localStorage.getItem('token')) {
      this.appLoaded = true;
      this.router.navigateByUrl('/agreements/createAccount');
    }
    // SIMS-6795 Refresh WebApp UI navigates to an error page
    else if (url.includes('agreements')) {
      if (localStorage.getItem('token') != null) {
        let tokenVal = localStorage.getItem('token');
        this.router.navigateByUrl('/?token=' + tokenVal);
        this.navigationMethod(tokenVal);
      }
    }
  }

  navigationMethod(paramValue) {
    this.homeService.getAgreements(paramValue).subscribe((partyDetails: PartyAgreementResponseModel) => {
      if (partyDetails != null) {
        localStorage.setItem('token', paramValue);
        this.appLoaded = true;
        this.router.navigateByUrl('/agreements');
        return;
      }
    },
      error => {
        if (error.status === 401 && (error.error.errors[0].code === 'SPA023' ||
          error.error.errors[0].code === 'SPA021' || error.error.errors[0].code === 'SPA022')) {
          localStorage.removeItem('token');
          this.isLinkExpired = true;
          this.appLoaded = true;
          this.router.navigateByUrl('/agreements/linkexpired');
          return;
        }
        this.router.navigateByUrl('/agreements/linkexpired');
      });
  }
  /*
  ngOnInit(): void {
    this.token = this.getParamValueQueryString('token');
    console.log("TOEKN >>>> " + this.token);
    // to call getsignuptoken service locally
    if(this.token == null || localStorage.getItem('token') == null){
     this.token = this.homeService.getSignUpTokenService();
    console.log("TOEKN from code>>>> " + this.token);
     }
    if (this.token != null) {
      localStorage.setItem('token', this.token);
      let partyAgreementsObservable = this.homeService.getAgreements(this.token);
      partyAgreementsObservable.subscribe((partyDetails: PartyAgreementResponseModel) => {
        if (partyAgreementsObservable != null) {
          this.appLoaded = true;
          this.router.navigateByUrl('/agreements');
        }
      },
        error => {
          if (error.status === 401 && (error.error.errors[0].code === 'SPA023' ||
            error.error.errors[0].code === 'SPA021' || error.error.errors[0].code === 'SPA022')) {
            localStorage.removeItem('token');
            this.isLinkExpired = true;
            this.appLoaded = true;
            this.router.navigateByUrl('/agreements/linkexpired');
          }
          this.router.navigateByUrl('/agreements/linkexpired');
        });
    }
    else {
      this.appLoaded = true;
    }
  }
  */

  getParamValueQueryString(paramName) {
   // const url = window.location.href;
  // SIMS-6821 - Indefinite processing on click of txt2Ap link
   const url = location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get(paramName);
    }
    console.log("param " + paramValue);
    return paramValue;
  }
  redirectPage(isLinkExpired: boolean): void {
    if (!isLinkExpired && this.appLoaded) {
      this.appLoaded = true;
      this.router.navigateByUrl('/agreements');
    }
    else {
      this.appLoaded = true;
      localStorage.removeItem('token');
      this.router.navigateByUrl('/linkexpired');
    }
  }

  ngOnDestroy() {
    this.appLoaded = true;
  }
}
