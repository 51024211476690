import { Injectable } from '@angular/core';
import { ClubAgreementModel, PartyAgreementModel, CustomerModel } from './models/partyAgreementModel';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { ApiPaymentMethodModel } from './models/apiPaymentMethodModel';
import { AgreementModel } from './models/agreementModel';
import { DialogService } from 'ng2-bootstrap-modal';
import { DatePipe } from '@angular/common';
import { SetupAutopayProcessingModalComponent } from './components/setup-autopay-processing-modal/setup-autopay-processing-modal.component';
import { SetupAutopaySuccessComponent } from './components/setup-autopay-success/setup-autopay-success.component';
import { ConnectionIssueModalComponent } from './components/connection-issue-modal/connection-issue-modal.component';
import { CardIssueModalComponent } from './components/card-issue-modal/card-issue-modal.component';
import { RegisterationModalComponent } from './components/registeration-modal/registeration-modal.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private getCustomerAutoPaySummaryUrl = '/v1/spa/signup';
  private enrollAutoPayUrl = '/v1/enrollap';
  private createAccountUrl = '/v1/users/create';
  private updateSignUpUrl = '/v1/spa/signup/id/';
  private customerAcknowledgmentUrl = '/tnc';
  private token = null;

  // Stub data - starts
  agreements: AgreementModel[] = [
    {
      agreementId: "9999210375227",
      agreementNumber: '07846660876',
      agreementDescription: 'tv',
      autopay: true,
      partyPaymentToken: 6920104,
      scheduleCode: 'M',
      agreementSchedule: 'Monthly',
      agreementAmount: 183.99,
      nextDueDate: '2019-03-09',
      agreementStatus: 'Active',
      ldwAmount: 18.39,
      agreementType: 'AGR',
      clubMembershipId: '',
      amount: 0,
      clubSchedule: ''
    },
    {
      agreementId: "9999210350384",
      agreementNumber: '07846657541',
      agreementDescription: 'chair',
      autopay: false,
      partyPaymentToken: 6920104,
      scheduleCode: 'M',
      agreementSchedule: 'Monthly',
      agreementAmount: 128.99,
      nextDueDate: '2019-03-12',
      agreementStatus: 'Active',
      ldwAmount: 12.89,
      agreementType: 'AGR',
      clubMembershipId: '',
      amount: 0,
      clubSchedule: ''
    },
    {
      agreementId: "9999210374435",
      agreementNumber: '07846660180',
      agreementDescription: 'fridge',
      autopay: true,
      partyPaymentToken: 6920104,
      scheduleCode: 'M',
      agreementSchedule: 'Monthly',
      agreementAmount: 185.99,
      nextDueDate: '2019-03-28',
      agreementStatus: 'Active',
      ldwAmount: 18.59,
      agreementType: 'AGR',
      clubMembershipId: '',
      amount: 0,
      clubSchedule: ''
    },
    {
      agreementId: "9999210371250",
      agreementNumber: '07846659709',
      agreementDescription: 'Couch',
      autopay: false,
      scheduleCode: 'M',
      partyPaymentToken: 6920104,
      agreementSchedule: 'Monthly',
      agreementAmount: 128.99,
      nextDueDate: '2019-03-25',
      agreementStatus: 'Active',
      ldwAmount: 12.89,
      agreementType: 'AGR',
      clubMembershipId: '',
      amount: 0,
      clubSchedule: ''
    }
  ];
  clubAgreements: ClubAgreementModel[] = [
    {
      clubMembershipId: '99997951898',
      autopay: false,
      clubSchedule: 'Monthly',
      amount: 12.99,
      nextDueDate: '2019-04-12',
      clubStatus: 'Active',
      partyClubId: null
    }
  ];
  partyAgreementsDetails: PartyAgreementModel = {
    partyId: '9999400631067',
    oktaId:'00u9ddmtmy',
    firstName: 'steve',
    lastName: 'tester',
    partyAgreements: this.agreements,
    partyClubs: this.clubAgreements,
  };
  customer: CustomerModel = {
    phoneNumber: '', //add phone number to test local
    storeNumber: '21241',
    //  partyId: '42416302',
    partyId: '9999402399184',
    emailAddress: 'test@test.com'
  };

  public getDetails(): any {
    const partyAgreementsObservable = new Observable(observer => {
      setTimeout(() => {
        observer.next(this.partyAgreementsDetails);
      }, 6000);
    });
    return partyAgreementsObservable;
  }
  // Stub data - ends

  constructor(private apiService: ApiService,
    private dialogService : DialogService,private datePipe: DatePipe,private router: Router) { }

  getSignUpToken(): Observable<any> {
    console.log('customer : ' + this.customer.emailAddress);
    return this.apiService.post(false,this.getCustomerAutoPaySummaryUrl, this.customer);
  }
  getSignUpTokenService(): any {
    if (!localStorage.getItem('token')) {
      this.getSignUpToken()
        .subscribe(response => {
          if (response != null && response.token) {
            console.log('SPA Token ' + response.token);
            //this.token = response.token;
            localStorage.setItem('token', response.token);

          }
        },
          error => {
            // handle error
          });
    }
    return localStorage.getItem('token');
    // this.token;
  }
  getAgreements(token): Observable<any> {
    return this.apiService.get(this.getCustomerAutoPaySummaryUrl + '/' + token);
  }

  enrollInAutoPay(agreements:  AgreementModel[],
    paymentMethod: ApiPaymentMethodModel, user: PartyAgreementModel): void {
    this.dialogService.addDialog(SetupAutopayProcessingModalComponent, null);
    this.updateAutoPayRequest(this.mapAutoPayRequest(agreements, paymentMethod, user)).subscribe(response => {
      if (response != null && response.StatusCode ==="0") {
        this.dialogService.removeAll();
        if(user.oktaId){
          this.updateSignUpToken().subscribe(response =>{
            if (response != null && response.message === "Updated sucessfully."){
              localStorage.removeItem('token');
              console.log("updateSignUpToken Response " +response.message);
            }
          });
        this.dialogService.addDialog(SetupAutopaySuccessComponent, null);
        }else{
          localStorage.setItem('PARTYID',user.partyId);
          this.router.navigateByUrl('/agreements/createAccount');
        }
        console.log(response);
      }  
      if (response != null && response.StatusCode === "1") {
        this.dialogService.removeAll();
        this.dialogService.addDialog(CardIssueModalComponent, null);
        console.log(response);
      }
      if (response != null && response.StatusCode === "2") {
        this.dialogService.removeAll();
        this.dialogService.addDialog(ConnectionIssueModalComponent, null);
        console.log(response);
      }
      if (response != null && response.StatusCode === "5") {
        this.dialogService.removeAll();
        this.dialogService.addDialog(ConnectionIssueModalComponent, null);
        console.log(response);
      }
      if (response != null && response.StatusCode === "9") {
        this.dialogService.removeAll();
        this.dialogService.addDialog(ConnectionIssueModalComponent, null);
        console.log(response);
      }
      if (response != null && response.StatusCode === "4") {
        this.dialogService.removeAll();
        this.dialogService.addDialog(ConnectionIssueModalComponent, null);
        console.log(response);
      }
    }, (error) => {
      this.dialogService.removeAll();
      this.dialogService.addDialog(ConnectionIssueModalComponent, null);
      console.log(error);
    });
  }


  mapAutoPayRequest(agreements:  AgreementModel[], paymentMethod: ApiPaymentMethodModel, user: PartyAgreementModel): any {
    
    const request = {
      ClientID: 91,
      Body: {
        UpdateCustomerRequest: {
          CustomerRequest: {
            CustomerID: user.partyId,
            FirstName: user.firstName,
            LastName: user.lastName,
            oktaId: user.oktaId,
            CustomerInfoFlag:0,
            PaymentMethods: [

            ]
          },
          CustomerAgreementRequest: {
            Agreements: agreements.map(agreement => {
              return {
                AgreementID: agreement.agreementId,
                Type: agreement.agreementType
              };
            })
          }
        }
      }
    };
    request.Body.UpdateCustomerRequest.CustomerRequest.PaymentMethods.push(paymentMethod);

    return request;
  }

  updateAutoPayRequest(request: any): Observable<any> {
    //this.apiService.post(this.getCustomerAutoPaySummaryUrl, this.customer);
    return this.apiService.post(true, this.enrollAutoPayUrl, request);
  }

  updateOktaIdRequest(request: any): Observable<any> {
    //this.apiService.post(this.getCustomerAutoPaySummaryUrl, this.customer);
    return this.apiService.post(true, this.enrollAutoPayUrl, request);
  }

  updateSignUpToken() {
    console.log("updateSignUpToken Url " + this.updateSignUpUrl + localStorage.getItem('token'));
    return this.apiService.post(false, this.updateSignUpUrl + localStorage.getItem('token'), null);
  }
  recordAcknowledgmentFromCustomer(customerId) {
    const date = this.datePipe.transform(Date.now(), 'yyyyMMdd');
    const request = {
                    "partyId" : customerId,
                    "dateTime": date,
                    "tncText": 'I agree',
                    "tncType": 'AD',    
    };

    return this.apiService.postAck(false,this.customerAcknowledgmentUrl, request);
}

  createAccountAfterSetupPay(userData:any){
    this.dialogService.addDialog(SetupAutopayProcessingModalComponent, null);
    this.createAccountRequest(this.mapRegisterationRequest(userData)).subscribe(response => {
      if (response != null && response.StatusCode ==="0") {
        let oktaId = response.OktaId;
        if(oktaId){
          if (localStorage.getItem('PARTYID')) {
            this.updateOktaIdWithSetup(localStorage.getItem('PARTYID'),oktaId);
          } 
        }
      }else if(response != null && response.StatusCode ==="3"){
        this.dialogService.removeAll();
        this.dialogService.addDialog(ErrorModalComponent, null); 
      } 
    }, (error) => {
      this.dialogService.removeAll();
      this.dialogService.addDialog(ConnectionIssueModalComponent, null);
      console.log(error);
    });
  }

  updateOktaIdWithSetup(partyId,oktaId){
    this.updateOktaIdRequest(this.mapOktaIdUpdateRequest(partyId,oktaId)).subscribe(response => {
      if (response != null && response.StatusCode ==="0") {
        this.dialogService.removeAll();
        this.dialogService.addDialog(SetupAutopaySuccessComponent, null);
        localStorage.removeItem('token');
        localStorage.removeItem('PARTYID');
      }else{
        this.dialogService.removeAll();
        this.dialogService.addDialog(ErrorModalComponent, null); 
      } 
    }, (error) => {
      this.dialogService.removeAll();
      this.dialogService.addDialog(ConnectionIssueModalComponent, null);
      console.log(error);
    });
  }

  createAccountRequest(request: any): Observable<any> {
    return this.apiService.post(true, this.createAccountUrl, request);
  }

  mapRegisterationRequest(userData:any ): any {
    const request = {
      ClientID: 91,
      createAccount: {
        userName: userData.userName,
        password: userData.password,
        subscribe: userData.subscribe
        }
      };
    return request;
  }
  
  mapOktaIdUpdateRequest(partyId,oktaId): any {
    const request = {
      ClientID: 91,
      Body: {
        UpdateCustomerRequest: {
          CustomerRequest: {
            CustomerID: partyId,
            oktaId: oktaId,
            CustomerInfoFlag:1,
          },
          CustomerAgreementRequest: {
            
          }
        }
      }
    };
    return request;
  }

  
  

  
}
