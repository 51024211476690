import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule  } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { VantivIframeService, VantivConfig } from './shared/services/vantiv-iframe.service';
// import { CreditcardformComponent } from './home/creditcardform/creditcardform.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsService } from './shared/services/utils.service';
import { BootstrapModalModule } from 'ng2-bootstrap-modal';
// tslint:disable-next-line:max-line-length
import { SetupAutopayProcessingModalComponent } from './home/components/setup-autopay-processing-modal/setup-autopay-processing-modal.component';
import { ProcessingSpinnerComponent } from './shared/processing-spinner/processing-spinner.component';
import { WhyToSetUpAutoPayComponent } from './home/components/why-to-set-up-auto-pay/why-to-set-up-auto-pay.component';
import { SetupAutopaySuccessComponent } from './home/components/setup-autopay-success/setup-autopay-success.component';
import { CardIssueModalComponent } from './home/components/card-issue-modal/card-issue-modal.component';
import { ConnectionIssueModalComponent } from './home/components/connection-issue-modal/connection-issue-modal.component';
import { AutopayDisclosureModalComponent } from './home/components/autopay-disclosure-modal/autopay-disclosure-modal.component';


import { ErrorModalComponent } from './home/components/error-modal/error-modal.component';
import { DatePipe } from '@angular/common';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

@NgModule({
  declarations: [
    AppComponent,
    SetupAutopayProcessingModalComponent,
    ProcessingSpinnerComponent,
    WhyToSetUpAutoPayComponent,
    SetupAutopaySuccessComponent,
   CardIssueModalComponent,
   ConnectionIssueModalComponent,
   AutopayDisclosureModalComponent,
   ErrorModalComponent
  //  CreditcardformComponent
  ],
  exports: [
    ProcessingSpinnerComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [
          HttpClient
        ]
      }
    }),
    HttpClientModule,
    ReactiveFormsModule,
    BootstrapModalModule.forRoot({container: document.body})
  ],
  providers: [
    VantivIframeService,
    VantivConfig,
    UtilsService,
    DatePipe
  ],
   entryComponents: [
    SetupAutopayProcessingModalComponent,
    WhyToSetUpAutoPayComponent,
     SetupAutopaySuccessComponent,
     CardIssueModalComponent,
    ConnectionIssueModalComponent,
    AutopayDisclosureModalComponent,
    ErrorModalComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
